.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 18px;
}

.info-image {
  width: 64px;
  font-size: 18px;
}

.info-title {
  font-family: 'Bold', sans-serif;
  margin-top: 4px;
  color: white;
  font-size: 9px !important;
}

.info-range {
  font-family: 'Bold', sans-serif;
  color: white;
  /* font-size: 14px; */
  font-size: 18px;
}

.speedometer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}

.speedometer-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 18px;
}

.speedometer-gauge {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  font-size: 18px;
}

.background {
  width: 256px;
  position: absolute;
  transform: translateY(-40%);
  font-size: 18px;
}

.needle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 1s;
  font-size: 18px;
}

.needle-tip {
  position: absolute;
  width: 64px;
  height: 64px;
  z-index: 10;
  font-size: 18px;
}

.needle-base {
  width: 96px;
  transform: rotate(-30deg) translateX(-50%) translateY(-50%);
}

.percentage {
  color: white;
  /* font-size: 14px; */
  font-size: 18px;
  position: absolute;
}

.speedometer-info {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 18px;
}

@media (min-height: 190px) {
  .info-image {
    width: calc(64px / 1.5);
  }

  .info-title {
    font-size: calc(8px / 1.5);
  }

  .info-range {
    font-size: calc(14px / 1.5);
  }

  .background {
    width: calc(256px / 1.5);
  }

  .needle-tip {
    width: calc(64px / 1.5);
    height: calc(64px / 1.5);
  }

  .needle-base {
    width: calc(96px / 1.5);
  }

  .percentage {
    font-size: calc(14px / 1.5);
  }

  .speedometer-body {
    gap: calc(24px / 1.5);
  }

  .speedometer-info {
    gap: calc(8px / 1.5);
  }
}

@media (min-height: 490px) {
  .info-image {
    width: calc(64px / 1.4);
  }

  .info-title {
    font-size: calc(8px / 1.4);
  }

  .info-range {
    font-size: calc(14px / 1.4);
  }

  .background {
    width: calc(256px / 1.4);
  }

  .needle-tip {
    width: calc(64px / 1.4);
    height: calc(64px / 1.4);
  }

  .needle-base {
    width: calc(96px / 1.4);
  }

  .percentage {
    font-size: calc(14px / 1.4);
  }

  .speedometer-body {
    gap: calc(24px / 1.4);
  }

  .speedometer-info {
    gap: calc(8px / 1.4);
  }
}

@media (min-height: 640px) {
  .info-image {
    width: calc(64px / 1.3);
  }

  .info-title {
    font-size: calc(8px / 1.3);
  }

  .info-range {
    font-size: calc(14px / 1.3);
  }

  .background {
    width: calc(256px / 1.3);
  }

  .needle-tip {
    width: calc(64px / 1.3);
    height: calc(64px / 1.3);
  }

  .needle-base {
    width: calc(96px / 1.3);
  }

  .percentage {
    font-size: calc(14px / 1.3);
  }

  .speedometer-body {
    gap: calc(24px / 1.3);
  }

  .speedometer-info {
    gap: calc(8px / 1.3);
  }
}

@media (min-height: 720px) {
  .info-image {
    width: calc(64px / 1.2);
  }

  .info-title {
    font-size: calc(8px / 1.2);
  }

  .info-range {
    font-size: calc(14px / 1.2);
  }

  .background {
    width: calc(256px / 1.2);
  }

  .needle-tip {
    width: calc(64px / 1.2);
    height: calc(64px / 1.2);
  }

  .needle-base {
    width: calc(96px / 1.2);
  }

  .percentage {
    font-size: calc(14px / 1.2);
  }

  .speedometer-body {
    gap: calc(24px / 1.2);
  }

  .speedometer-info {
    gap: calc(8px / 1.2);
  }
}

@media (min-height: 800px) {
  .info-image {
    width: calc(64px / 1.1);
  }

  .info-title {
    font-size: calc(8px / 1.1);
  }

  .info-range {
    font-size: calc(14px / 1.1);
  }

  .background {
    width: calc(256px / 1.1);
  }

  .needle-tip {
    width: calc(64px / 1.1);
    height: calc(64px / 1.1);
  }

  .needle-base {
    width: calc(96px / 1.1);
  }

  .percentage {
    font-size: calc(14px / 1.1);
  }

  .speedometer-body {
    gap: calc(24px / 1.1);
  }

  .speedometer-info {
    gap: calc(8px / 1.1);
  }
}

@media (min-height: 910px) {
  .info-image {
    width: calc(64px / 1);
  }

  .info-title {
    font-size: calc(8px / 1);
  }

  .info-range {
    font-size: calc(14px / 1);
  }

  .background {
    width: calc(256px / 1);
  }

  .needle-tip {
    width: calc(64px / 1);
    height: calc(64px / 1);
  }

  .needle-base {
    width: calc(96px / 1);
  }

  .percentage {
    font-size: calc(14px / 1);
  }

  .speedometer-body {
    gap: calc(24px / 1);
  }

  .speedometer-info {
    gap: calc(8px / 1);
  }
}
