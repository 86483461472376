@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #1c1e37;
}

h2 {
  padding: 0;
}

input {
  outline: none;
  border: none;
}

@font-face {
  font-family: 'Bold';
  src: url('../src/fonts/ACROM-BOLD.TTF') format('truetype');
}

@font-face {
  font-family: 'Extrabold';
  src: url('../src/fonts/ACROM-EXTRABOLD.TTF') format('truetype');
}

@font-face {
  font-family: 'Light';
  src: url('../src/fonts/ACROM-LIGHT.TTF') format('truetype');
}

@font-face {
  font-family: 'Medium';
  src: url('../src/fonts/ACROM-MEDIUM.TTF') format('truetype');
}

@font-face {
  font-family: 'Regular';
  src: url('../src/fonts/ACROM-REGULAR.TTF') format('truetype');
}

@font-face {
  font-family: 'Thin';
  src: url('../src/fonts/ACROM-THIN.TTF') format('truetype');
}

/* LOADER CONTAINER */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

.container-upperlogo {
  display: flex;
  justify-content: center;
  width: 150px;
}

.container-upperlogo img {
  width: 100%;
}

.loader-container-video {
  width: 150px;
}

/* SPEEDOMETER CONTAINER */
.speed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

/* ADDRESS INPUT CONTAINER */
.address-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid rgb(174, 47, 181);
  border-radius: 17px;
  overflow: hidden;
}

.address-input-container-input {
  width: 300px;
  padding: 10px;
  background-color: rgb(28, 30, 55);
  border: none;
  color: white;
  font-family: 'Regular', sans-serif;
  font-size: 16px;
}

.address-input-container-input::placeholder {
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.button-container {
  display: flex;
  justify-content: space-around;
}

.change-btn {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid rgb(174, 47, 181);
  background-color: transparent;
  color: white;
  font-family: 'Bold', sans-serif;
  text-align: center;
  cursor: pointer;
}

.change-btn:hover {
  background-color: rgb(174, 47, 181);
}

.container-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check-btn {
  width: 250px;
  padding: 18px 0px;
  font-size: 16px;
  letter-spacing: 5px;
  cursor: pointer;
  color: white;
  background-color: rgb(28, 30, 55);
  border: 1px solid rgb(174, 47, 181);
  border-radius: 7px;
  font-family: 'Bold', sans-serif;
}

.buy-btn {
  width: 180px;
  padding: 11px 19px;
  font-size: 14px;
  letter-spacing: 5px;
  cursor: pointer;
  color: white;
  background-color: rgb(28, 30, 55);
  border: 1px solid rgb(174, 47, 181);
  border-radius: 7px;
  font-family: 'Regular', sans-serif;
}

.container-buttons img {
  margin-top: 10px;
  width: 100px;
}

/* RESULT CONTAINER */
.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

.risk-level-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 272px;
  padding: 8px;
  border: 1px solid white;
  border-radius: 7px;
  color: white;
}

.risk-percentage {
  font-size: 20px;
  width: 35%;
  font-family: 'Medium', sans-serif;
}

.risk-info {
  display: flex;
  flex-direction: column;
  font-family: 'Regular', sans-serif;
  font-size: 8px;
}

.risk-description {
  margin-bottom: 10px;
  letter-spacing: -0.5px;
}

.risk-scale {
  display: flex;
  justify-content: space-between;
  font-family: 'Medium', sans-serif;
}

.risk-scale-green {
  color: green;
}

.risk-scale-yellow {
  color: yellow;
}

.risk-scale-red {
  color: red;
}

.report-tittle {
  margin: 10px 0;
  color: white;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Regular', sans-serif;
}

/* PHRASES CONTAINER */
.images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 35%;
  width: 80%;
  padding: 10px 0;
  /* border: 1px solid white; */
  border-radius: 8px;
}

.images-container::-webkit-scrollbar {
  width: 4px;
}

.images-container::-webkit-scrollbar-track {
  background: rgb(28, 30, 55);
}

.images-container::-webkit-scrollbar-thumb {
  background: rgb(128, 0, 128);
  border-radius: 10px;
}

.images-container::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 0, 128, 0.8);
}

.images-container {
  scrollbar-width: thin;
  scrollbar-color: rgb(128, 0, 128) rgb(28, 30, 55);
}

.phrase-container {
  display: flex;
  align-items: center;
}

.white-bordered-container {
  display: flex;
  align-items: center;
  margin: 9px 0;
  padding: 7px;
  border: 1px solid white;
  border-radius: 8px;
  width: 272px;
}

.white-bordered-container > img {
  width: 35px !important;
  height: auto !important;
}

.phrase {
  font-family: 'Bold', sans-serif;
  font-size: 12px;
  color: white;
  margin: 0 10px;
}

.yellow-circle,
.green-circle,
.red-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 10px;
  flex-shrink: 0;
}

.yellow-circle {
  background-color: yellow;
}

.green-circle {
  background-color: green;
}

.red-circle {
  background-color: red;
}

/* DOWNLOAD CONTAINER*/
.download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

.download-container-email-input {
  width: 240px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
  background-color: rgb(28, 30, 55);
  border: 1px solid rgb(174, 47, 181);
  border-radius: 7px;
  color: white;
  font-family: 'Regular', sans-serif;
}

.download-container-email-input::placeholder {
  letter-spacing: 7px;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.footnote-wrapper {
  display: flex;
  width: 250px;
  margin-bottom: 10px;
  gap: 20px;
  align-items: center;
  font-family: 'Regular', sans-serif;
}

.clip-icon {
  width: 50px;
  height: 50px;
}

.footnote-text-smal {
  color: white;
  font-size: 10px;
  margin-bottom: 10px;
}

.footnote-text-medium {
  color: white;
  font-size: 12px;
}

@media (min-height: 490px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 190px;
  }

  .loader-container-video {
    width: 190px;
  }

  /* ADDRESS INPUT CONTAINER */
  .check-btn {
    font-size: 18px;
  }

  .buy-btn {
    font-size: 15px;
  }

  .container-buttons img {
    width: 110px;
  }

  /* RESULT CONTAINER */

  .risk-percentage {
    font-size: 22px;
  }

  .risk-info {
    font-size: 9px;
  }

  .phrase-container img {
    width: 50px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    font-size: 17px;
    margin-bottom: 20px;
  }
}

@media (min-height: 640px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 220px;
  }

  .loader-container-video {
    width: 220px;
  }

  /* ADDRESS INPUT CONTAINER */
  .check-btn {
    width: 230px;
    font-size: 19px;
  }

  .buy-btn {
    width: 190px;
    font-size: 15px;
  }

  .container-buttons img {
    margin-top: 14px;
    width: 120px;
  }

  /* RESULT CONTAINER */
  .risk-percentage {
    font-size: 24px;
  }

  .risk-info {
    font-size: 10px;
  }

  .phrase-container img {
    width: 60px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 250px;
    margin-bottom: 25px;
  }
}

@media (min-height: 720px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 240px;
  }

  .loader-container-video {
    width: 240px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    padding: 11px;
  }

  .check-btn {
    width: 272px;
  }

  .buy-btn {
    font-size: 16px;
  }

  .container-buttons img {
    width: 120px;
  }

  /* RESULT CONTAINER */

  .risk-percentage {
    font-size: 26px;
  }

  .risk-info {
    font-size: 11px;
  }

  .phrase-container img {
    width: 70px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 260px;
    padding: 11px;
    font-size: 18px;
    margin-bottom: 30px;
  }
}

@media (min-height: 800px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 260px;
  }

  .loader-container-video {
    width: 260px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    padding: 12px;
  }

  .check-btn {
    padding: 23px;
    font-size: 20px;
  }

  .buy-btn {
    padding: 13px 21px;
  }

  .container-buttons img {
    margin-top: 18px;
  }

  /* RESULT CONTAINER */
  .risk-level-container {
    padding: 11px;
  }

  .risk-percentage {
    font-size: 28px;
  }

  .risk-info {
    font-size: 12px;
  }

  .phrase-container img {
    width: 80px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 270px;
    padding: 12px;
    margin-bottom: 38px;
  }
}

@media (min-height: 910px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 290px;
  }

  .loader-container-video {
    width: 290px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    width: 350px;
    padding: 14px;
    font-size: 18px;
  }

  .check-btn {
    width: 260px;
  }

  .buy-btn {
    width: 200px;
    padding: 15px 23px;
    font-size: 18px;
  }

  .container-buttons img {
    margin-top: 18px;
  }

  /* RESULT CONTAINER */
  .risk-level-container {
    padding: 12px;
  }

  .risk-percentage {
    font-size: 30px;
  }

  .risk-info {
    font-size: 13px;
  }

  .phrase-container img {
    width: 85px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 290px;
    padding: 14px;
    font-size: 20px;
    margin-bottom: 50px;
  }
}

@media (min-height: 1020px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 340px;
  }

  .loader-container-video {
    width: 340px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    width: 380px;
    padding: 15px;
  }

  .check-btn {
    width: 270px;
    padding: 24px;
    font-size: 21px;
  }

  .buy-btn {
    width: 220px;
  }

  .container-buttons img {
    margin-top: 20px;
  }

  /* RESULT CONTAINER */
  .risk-level-container {
    padding: 13px;
  }

  .risk-percentage {
    font-size: 31px;
  }

  .risk-info {
    font-size: 14px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 340px;
    padding: 15px;
    margin-bottom: 60px;
  }
}

@media (min-height: 1160px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 370px;
  }

  .loader-container-video {
    width: 370px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    width: 430px;
    padding: 16px;
    font-size: 21px;
  }

  .check-btn {
    width: 290px;
    padding: 25px;
    font-size: 23px;
  }

  .buy-btn {
    width: 240px;
    padding: 17px 25px;
    font-size: 19px;
  }

  .container-buttons img {
    margin-top: 22px;
    width: 125px;
  }

  /* RESULT CONTAINER */
  .risk-level-container {
    width: 280px;
    padding: 14px;
  }

  .risk-percentage {
    font-size: 32px;
  }

  .risk-info {
    font-size: 15px;
  }

  .phrase-container img {
    width: 95px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 370px;
    padding: 16px;
    font-size: 21px;
    margin-bottom: 70px;
  }
}

@media (min-height: 1280px) {
  /* LOADER CONTAINER */
  .container-upperlogo {
    width: 420px;
  }

  .loader-container-video {
    width: 420px;
  }

  /* ADDRESS INPUT CONTAINER */
  .address-input-container-input {
    width: 490px;
    padding: 17px;
    font-size: 22px;
  }

  .check-btn {
    width: 310px;
    font-size: 24px;
  }

  .buy-btn {
    width: 250px;
    padding: 18px 26px;
    font-size: 20px;
  }

  /* RESULT CONTAINER */
  .risk-level-container {
    width: 290px;
    padding: 15px;
  }

  .risk-info {
    font-size: 16px;
  }

  /* DOWNLOAD CONTAINER*/
  .download-container-email-input {
    width: 420px;
    padding: 17px;
    font-size: 22px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-height: 600px) {
  .loader-container-video {
    width: 150px;
  }

  .white-bordered-container {
    margin: 2px 0;
    padding: 2px;
  }

  .phrase {
    font-size: 10px;
  }

  .clip-icon {
    width: 30px;
    height: 30px;
  }

  .footnote-text-smal {
    font-size: 8px;
    margin-bottom: 3px;
  }

  .footnote-text-medium {
    font-size: 10px;
  }

  .check-btn {
    padding: 10px 0;
    font-size: 14px;
  }
}
