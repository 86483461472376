.container {
  position: relative;
  width: 100%;
}

.label {
  margin-bottom: 12px;
  margin-left: 14px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.inputContainer {
  width: 100%;
  border-radius: 9px;
  transition: all 0.3s;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid #6e6f83;
  margin-bottom: 20px;
}

.inputContainer.focusWithin {
  border-color: #b52e32;
}

.input {
  width: 100%;
  height: 64px;
  background-color: #1c1e37;

  outline: 0;
  color: white;
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.input:focus::placeholder {
  opacity: 0;
}

.input:disabled {
  background-color: #2c2f48;
  cursor: not-allowed;
}
