.container {
  position: relative;
  width: 100%;
}

.label {
  margin-bottom: 12px;
  margin-left: 14px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.select {
  width: 100%;
  border-radius: 9px;
  transition: all 0.3s;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #6e6f83;
  background-color: transparent;
  height: 64px;
  padding: 8px 20px;
  color: rgba(255, 255, 255, 0.6);
  appearance: none;
  background: url(icon.png) no-repeat right center;
  background-position-x: calc(100% - 10px);
  background-size: 12px; /* Задаем размер иконки */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select:focus {
  outline: none;
  box-shadow: none;
}

.modalContainer {
  width: 100%;
  border-radius: 9px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  border: 1px solid #6e6f83;
  background-color: transparent;
  height: 64px;
  color: rgba(255, 255, 255, 0.6);
}

.coin {
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  height: 64px;
  background-color: rgb(174, 46, 181);
}

.coin:hover {
  background-color: rgb(174, 46, 181);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
